<template>
  <CheckboxSelect
      trackBy="id"
      optionLabel="label"
      label="Solicitações..."
      v-model="value.request_types"
      :options="requestTypesOptions"
  />
</template>
<script>

import { requestTypes } from "@sp/config/budget.js"
export default {
  name: "BudgetRequestTypesSelect",
  components: {
    CheckboxSelect: () => import('@/components/CheckboxSelect'),
  },
  props: {
    value: Object
  },
  data() {
    return {
      requestTypesOptions: []
    }
  },
  mounted() {
      requestTypes.forEach((v, k) => this.requestTypesOptions.push({id: k, label: v.label}))
  },
  methods: {
  }
}
</script>

<style lang="scss">
.request-types-combo.form-control {
  padding: 0;
}
</style>
<style scoped lang="scss">
 .option__title {
   padding: 7px 10px;
   border-radius: 15px;
 }
</style>